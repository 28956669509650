<template>
  <audio loop ref="audio">
    <source src="../static/alert.mp3" type="audio/mp3" />
  </audio>
</template>

<script>
import mqtt from 'mqtt';
export default {
  name: 'AudioData',
  data() {
    return {
      alertCount: null,
      NotificationList: [],
      notifications: {},
      client: mqtt.connect(this.$MQTT, { connectTimeout: 40000, clean: true, username: 'bqes', password: 'Bq@201503' })
    };
  },
  destroyed() {
    this.NotificationList.forEach((item, index) => {
      this.NotificationList.splice(index, 1);
      item.name.close();
    });
    this.client.end();
    if (this.$refs.audio) {
      this.$refs.audio.pause();
    }
  },
  mounted() {
    if (this.$permission('110')) {
      this.alertCount = this.debounce(() => {
        this.$store.commit('getCount');
      }); // 此处getSearchCompanyCityInfo是在methods中定义的函数， 500单位为毫秒，延迟500毫秒再调用，下同
      this.getMqtt();
    }
  },
  watch: {
    NotificationList: {
      handler() {
        this.alertCount();
      },
      deep: true
    }
  },
  methods: {
    debounce(fn, delay = 500) {
      let timer = null;
      return function () {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn();
        }, delay);
      };
    },
    typeName(type) {
      if (type == 5) {
        return '热力';
      } else if (type == 3) {
        return '污水';
      }
    },
    linkUnit(unitId) {
      sessionStorage.unitId = unitId;
      this.$router.push({ name: 'monitoringUnit' });
    },
    //关闭单个通知
    closeNotification(id) {
      this.NotificationList.forEach((item, index) => {
        if (id == item.id) {
          this.NotificationList.splice(index, 1);
          item.name.close();
        }
      });
      if (this.NotificationList.length == 0) {
        if (this.$refs.audio) {
          this.$refs.audio.pause();
        }
      }
      this.$nextTick(() => {
        this.NotificationList.forEach((item, i) => {
          item.name.$el.style.top = i * 144 + 20 + 'px';
        });
      });
    },
    getMqtt(message) {
      var that = this;
      let notify = null;
      this.client.on('connect', (e) => {
        this.client.subscribe(['/message/dsp-alert'], (err) => {
          if (!err) {
          } else {
          }
        });
      });
      // 接收消息处理
      this.client.on('message', (topic, data) => {
        if (that.$refs.audio) {
          that.$refs.audio.play();
        }
        // this.$store.commit('getCount');
        const h = this.$createElement;
        let str = JSON.parse(data.toString());
        notify = this.$notify({
          title: str.name,
          type: 'warning',
          showClose: false,
          message: h('div', null, [
            h('p', {}, str.deviceName),
            h('p', { style: 'margin-top:4px;' }, '能源类型：' + that.typeName(str.terminalType)),
            h('p', { style: 'margin-top:4px;' }, str.dataTime),
            h('i', { class: 'el-icon-close close', on: { click: that.closeNotification.bind(this, str.alertId) } })
          ]),
          duration: 0,
          offset: 70
        });
        this.notifications[str.id] = notify;
        this.NotificationList.unshift({ id: str.alertId, name: notify });
        this.$nextTick(() => {
          this.NotificationList.forEach((item, i) => {
            item.name.$el.style.top = i * 144 + 20 + 'px';
          });
        });
      });
      // 断开发起重连
      this.client.on('reconnect', (error) => {
        // this.client.end();
      });
      // 链接异常处理
      this.client.on('error', (error) => {
        // this.client.end();
      });
    }
  }
};
</script>
<style>
.notify {
  width: 350px !important;
}
</style>
<style scoped lang="less">
/deep/ .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 111;
}
</style>
