import { dynamicRoutes } from '@/router/router';
import { getToken, removeToken } from '@/utils/token';
import { router, createRouter } from '@/router';
export default {
  namespaced: true,
  state: {
    permissionList: [],
    menuList: [],
    token: null,
    enterprise:null,//是否是特殊账号 不为空为特殊账号
  },
  mutations: {
    logout(state) {
      state.menuList = [];
      state.permissionList = [];
      state.token = null;
      state.enterprise = null;
      removeToken();
      this.commit('user/resetRouter');
    },
    //重置用户路由
    resetRouter() {
      const newRouter = createRouter();
      router.matcher = newRouter.matcher;
    },
    //添加路由
    addUserRoute(state) {
      state.menuList.forEach(item => {
        if (item.meta && item.meta.hiddenMenu) {
          router.addRoute(item);
        } else {
          router.addRoute('menu', item);
        }
      });
    },
    setPermissionList(state, data) {
      state.permissionList = data.permissionsList;
      state.enterprise = data.enterprise;
      state.token = getToken();
    },
    //根据用户权限过滤路由表
    filterRoleMenu(state) {
      let arr = [];
      for (let i = 0; i < dynamicRoutes.length; i++) {
        if (state.permissionList.indexOf(dynamicRoutes[i].meta.code) != -1) {
          arr.push(dynamicRoutes[i]);
        }
      }
      state.menuList = arr;
    }
  },
  actions: {},
  getters: {}
};
