//静态路由表
const staticRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    replace: true,
    component: () => import('../views/login')
  },
  {
    path: '/scheduling',
    name: 'menu',
    component: () => import('../views/menu/index'),
    children: []
  }
];
//动态路由表
const dynamicRoutes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      code: '101_201',
      name: '项目总览',
      icon: 'icon-overview',
      hiddenMenu: true
    },
    component: () => import('../views/home/index')
  },
  //热力
  {
    path: '/scheduling/heatMonitoring',
    name: 'heatMonitoring',
    meta: {
      code: '102_201',
      name: '热力供/用能',
      // menuList: [
      //   { path: '/scheduling/heatMonitoring/6', name: '华电关口表数据' },
      //   { path: '/scheduling/heatMonitoring/7', name: '华电锅炉数据' },
      //   { path: '/scheduling/heatMonitoring/1', name: '蒸汽分输站' },
      //   { path: '/scheduling/heatMonitoring/2', name: '精细化工区' },
      //   { path: '/scheduling/heatMonitoring/3', name: '创新路段企业' },
      //   { path: '/scheduling/heatMonitoring/4', name: '润滑油组团区' },
      //   { path: '/scheduling/heatMonitoring/5', name: '仓储物流区' }
      // ],
      parentMessage: { name: '热力实时监控', icon: 'icon-reli' }
    },
    component: () => import('../views/heatMonitoring')
  },
  {
    path: '/scheduling/heatPipe/index',
    name: 'heatPipe',
    meta: {
      code: '102_202_301',
      name: '热力管网图',
      parentMessage: { name: '热力实时监控', icon: 'icon-reliicon' }
    },
    component: () => import('../views/heatPipe/index')
  },
  //污水
  {
    path: '/scheduling/sewage/index',
    name: 'sewageIndex',
    meta: {
      code: '105_201_301',
      name: '污水厂',
      parentMessage: { name: '污水实时监控', icon: 'icon-nav_wushui' }
    },
    component: () => import('../views/sewage/index')
  },
  {
    path: '/scheduling/sewage/unit',
    name: 'sewageUnit',
    meta: {
      code: '105_202',
      name: '排污用户',
      parentMessage: { name: '污水实时监控', icon: 'icon-nav_wushui' }
    },
    component: () => import('../views/sewage/unit')
  },
  {
    path: '/scheduling/sewage/sewagePipeImg',
    name: 'sewagePipeImg',
    meta: {
      code: '105_203',
      name: '污水管网图',
      parentMessage: { name: '污水实时监控', icon: 'icon-nav_wushui' }
    },
    component: () => import('../views/sewage/sewagePipeImg')
  },
  //燃气
  {
    path: '/scheduling/gas/index',
    name: 'gasIndex',
    meta: {
      code: '104_201_301',
      name: '燃气门站',
      parentMessage: { name: '燃气实时监控', icon: 'icon-nav_ranqi' }
    },
    component: () => import('../views/gas/index')
  },
  {
    path: '/scheduling/gas/unit',
    name: 'gasUnit',
    meta: {
      code: '104_202',
      name: '燃气用户',
      parentMessage: { name: '燃气实时监控', icon: 'icon-nav_ranqi' }
    },
    component: () => import('../views/gas/unit')
  },
  {
    path: '/scheduling/gas/gasPipeImg',
    name: 'gasPipeImg',
    meta: {
      code: '104_203_301',
      name: '燃气管网图',
      parentMessage: { name: '燃气实时监控', icon: 'icon-nav_ranqi' }
    },
    component: () => import('../views/gas/gasPipeImg')
  },
  //自来水
  {
    path: '/scheduling/water/index',
    name: 'waterIndex',
    meta: {
      code: '103_201_301',
      name: '自来水厂',
      parentMessage: { name: '自来水实时监控', icon: 'icon-tap-water' }
    },
    component: () => import('../views/water/index')
  },
  {
    path: '/scheduling/water/unit',
    name: 'waterUnit',
    meta: {
      code: '103_202',
      name: '水用户',
      parentMessage: { name: '自来水实时监控', icon: 'icon-tap-water' }
    },
    component: () => import('../views/water/unit')
  },
  {
    path: '/scheduling/water/waterPipeImg',
    name: 'waterPipeImg',
    meta: {
      code: '103_203',
      name: '自来水管网图',
      parentMessage: { name: '自来水实时监控', icon: 'icon-tap-water' }
    },
    component: () => import('../views/water/waterPipeImg')
  },
  //告警管理
  {
    path: '/scheduling/alert',
    name: 'alert',
    meta: {
      code: '110',
      name: '告警管理',
      icon: 'icon-navicon-09'
    },
    component: () => import('../views/alert/index')
  },
  //数据汇总
  {
    path: '/scheduling/data/summary',
    name: 'summary',
    meta: {
      code: '106_201',
      name: '数据汇总',
      icon: 'icon-hz'
    },
    component: () => import('../views/summary/index')
  },
  //群发管理
  {
    path: '/scheduling/message/send',
    name: 'send',
    meta: {
      code: '107_201',
      name: '短信群发',
      parentMessage: { name: '群发管理', icon: 'icon-dx' }
    },
    component: () => import('../views/message/send')
  },
  {
    path: '/scheduling/message/template',
    name: 'template',
    meta: {
      code: '107_201',
      name: '模板管理',
      parentMessage: { name: '群发管理', icon: 'icon-not' }
    },
    component: () => import('../views/message/template')
  },
  {
    path: '/scheduling/message/manage',
    name: 'manage',
    meta: {
      code: '107_201',
      name: '联系人管理',
      parentMessage: { name: '群发管理', icon: 'icon-not' }
    },
    component: () => import('../views/message/manage')
  },
  {
    path: '/scheduling/message/record',
    name: 'record',
    meta: {
      code: '107_201',
      name: '发送记录',
      parentMessage: { name: '群发管理', icon: 'icon-not' }
    },
    component: () => import('../views/message/record')
  },
  //能源报告
  {
    path: '/scheduling/report',
    name: 'report',
    meta: {
      code: '109_201',
      name: '能源报告',
      icon: 'icon-report'
    },
    component: () => import('../views/report/index')
  },
  //参数配置
  {
    path: '/scheduling/userConfig',
    name: 'userConfig',
    meta: {
      code: '108',
      name: '参数配置',
      icon: 'icon-canshu'
    },
    component: () => import('../views/userConfig')
  }
];

export { staticRoutes, dynamicRoutes };
