import axios from 'axios';
import Vue from 'vue';
import { Message, Loading } from 'element-ui';
import { removeToken, getToken } from './utils/token';
import { router } from './router/index';

Vue.component(Message);

var instance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  timeout: 20000 //请求超时时间，毫秒
});
//开始加载动画
var loadingText = null;
var loadingNum = 0; //初始化接口数量
var loadingTimeout = null;
export function startLoading() {
  loadingText = Loading.service({
    background: 'rgba(0, 0, 0, 0)',
    customClass: 'globalLoading'
  });
}
//结束加载动画
export function endLoading() {
  loadingNum = 0;
  let timer = setTimeout(() => {
    if (loadingText) {
      loadingText.close();
      clearTimeout(loadingTimeout);
    }
    loadingTimeout = null;
    clearTimeout(timer);
    timer = null;
  }, 500);
}
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    if (!loadingTimeout) {
      loadingTimeout = setTimeout(() => {
        loadingNum++;
        startLoading();
      }, 500);
    }
    return config;
  },
  function (error) {
    endLoading();
    clearTimeout(loadingTimeout);
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// respone拦截器
instance.interceptors.response.use(
  (response) => {
    loadingNum--;
    if (loadingNum <= 0) {
      //结束加载动画
      endLoading();
      clearTimeout(loadingTimeout);
    }
    return response;
  },
  (error) => {
    endLoading();
    clearTimeout(loadingTimeout);
    return Promise.reject(error);
  }
);
const ajax = {
  get: function (url, params) {
    instance.defaults.headers.common['Authorization'] = getToken();
    return new Promise((resolve, reject) => {
      instance
        .get(url, { params: params })
        .then((response) => {
          if (response.data.code === 200) {
            resolve(response.data);
          } else if (response.data.code === 401) {
            Message.closeAll();
            Message.error('请重新登录');
            removeToken();
            router.push({ name: 'login' });
          } else {
            Message.error(response.data.info);
          }
          endLoading();
          clearTimeout(loadingTimeout);
        })
        .catch((error) => {
          if (error.message.includes('timeout')) {
            Message.error('请求超时');
          } else {
            Message.close();
            Message.error('服务器出错了,请稍后重试');
          }
          endLoading();
          clearTimeout(loadingTimeout);
          reject(error);
        });
    });
  },
  post: function (url, data, login) {
    instance.defaults.headers.common['Authorization'] = getToken();
    return new Promise((resolve, reject) => {
      instance
        .post(url, data)
        .then((response) => {
          if (login) {
            resolve(response.data);
          }
          if (response.data.code === 200) {
            resolve(response.data);
          } else if (response.data.code === 401) {
            Message.closeAll();
            Message.error('请重新登录');
            removeToken();
            router.push({ name: 'login' });
          } else {
            Message.error(response.data.info);
          }
          endLoading();
          clearTimeout(loadingTimeout);
        })
        .catch((error) => {
          if (error.message.includes('timeout')) {
            Message.error('请求超时');
          } else {
            Message.close();
            Message.error('服务器出错了,请稍后重试');
          }
          endLoading();
          clearTimeout(loadingTimeout);
          reject(error);
        });
    });
  }
};

export default ajax;
