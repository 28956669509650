export function getNum(val, num, name) {
  if ((val || val === 0) && val !== '' && val != '-') {
    if (name && name != '') {
      switch (name) {
        case 'pH值':
          return val.toFixed(1);
          break;
        case '总氮':
          return val.toFixed(3);
          break;
        case '总磷':
          return val.toFixed(3);
          break;
        case '氨氮':
          return val.toFixed(3);
          break;
        case '化学需氧量':
          return val.toFixed(3);
          break;
        case '悬浮物':
          return val.toFixed(3);
          break;
        case '瞬时流量':
          return val.toFixed(1);
          break;
        case '累积流量':
          return val.toFixed(1);
          break;
        case '昨日累积流量':
          return val.toFixed(1);
          break;
        case '本月累积流量':
          return val.toFixed(1);
          break;
        case '水中油':
          return val.toFixed(3);
          break;
        case '压力':
          return val.toFixed(2);
          break;
        case '水温':
          return val.toFixed(1);
          break;
        case 'TDS':
          return val.toFixed(3);
          break;
        case '电导率':
          return val.toFixed(3);
          break;
        case '总铬':
          return val.toFixed(3);
          break;
        case '原水池液位':
          return val.toFixed(2);
          break;
        case '清水池液位':
          return val.toFixed(2);
          break;
        case '南侧出水总管流量':
          return val.toFixed(1);
          break;
        case '南侧出水总管压力':
          return val.toFixed(2);
          break;
        case '北侧出水总管流量':
          return val.toFixed(1);
          break;
        case '北侧出水总管压力':
          return val.toFixed(2);
          break;
        case '来气压力':
          return val.toFixed(2);
          break;
        case '来气温度':
          return val.toFixed(1);
          break;
        case '出站压力':
          return val.toFixed(2);
          break;
        case '出站温度':
          return val.toFixed(1);
          break;
      }
    } else {
      if (num) {
        return val.toFixed(num);
      } else {
        return val.toFixed(1);
      }
    }
  } else {
    if (val == '-') {
      return val;
    }
    return '--';
  }
}
export function getAlert(val) {
  if (val) {
    return 'color: #F56C6C';
  } else {
    return false;
  }
}
