import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { staticRoutes } from './router';

Vue.use(Router);

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

export const createRouter = () =>
  new Router({
    mode: 'history',
    routes: staticRoutes // 路由路径
  });

export const router = createRouter();
router.beforeEach((to, from, next) => {
  if (store.state.user.token && store.state.user.menuList.length == 0) {
    store.commit('user/filterRoleMenu');
    store.commit('user/addUserRoute');
    next({ ...to, replace: true });
  } else {
    if (!store.state.user.token && to.name != 'login') {
      next('/login');
    } else if (to.matched.length === 0) {
      store.commit('user/logout');
      next('/login');
    } else if (store.state.user.token && to.path == '/') {
      next(from.path == '/' ? store.state.user.menuList[0].path : from.path);
    } else {
      next();
    }
  }
});
router.afterEach(() => {
  window.scrollTo({ top: 0 });
});
