<template>
  <div id="app">
    <audioData v-if="pathRoute.indexOf('login') == -1" />
    <router-view />
  </div>
</template>
<script>
import audioData from '@/audio';

export default {
  name: 'App',
  components: {
    audioData
  },
  computed: {
    pathRoute() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="less">
#app {
  height: 100%;
  width: 100%;
  touch-action: none;
  font-family: PingFang SC !important;
}
.globalLoading {
  .path {
    stroke: rgba(78, 84, 93, 0.5) !important;
    stroke-width: 8 !important;
  }
}
.el-date-range-picker__time-picker-wrap:nth-child(1) .el-input {
  z-index: -1;
}
.table-btn {
  display: inline;
  .el-button {
    padding: 0;
  }
}
//提示框表格
.report-popover {
  width: 450px !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.08) !important;
  border-radius: 6px 6px 6px 6px !important;
  border: none !important;
  padding: 0 15px 20px 15px !important;
  & > p {
    margin: 14px 0;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: @text1;
    font-weight: 600;
  }
  .el-table th {
    background-color: #f2f6fc;
    color: @text1;
    height: 34px !important;
    font-weight: 400;
  }
  .el-table td {
    height: 34px !important;
    color: @text2;
  }
}
.pagination {
  margin-top: 32px;
  margin-right: 20px;
  text-align: right;
}
.el-message--error {
  z-index: 5000 !important;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: num;
  src: url('../static/BAHNSCHRIFT.TTF');
}

.el-table .el-table__cell {
  padding: 0px;
}
.el-table .el-table__header .el-table__cell {
  height: 50px;
  .cell {
    height: 34px;
    line-height: 34px;
  }
}
.el-table .el-table__body .el-table__cell {
  padding: 13px 0px;
  .cell {
    line-height: normal;
  }
}
.el-table--border th.el-table__cell,
.el-table__fixed-right-patch {
  padding: 0px;
}
.grid-content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 258px);
  grid-row-gap: 20px;
  grid-column-gap: 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-dialog__body {
  .page-form {
    padding: 0px !important;
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    border-radius: 8px;
    background: #cccccc;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }
}
.rowClass {
  background: #f2f6fc !important;
}

.tooltip-title {
  display: flex;
  align-items: center;
  & > img {
    margin-left: 7px;
    vertical-align: middle;
    margin-top: -4px;
  }
}
.el-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > .el-empty__description {
    color: #606066;
  }
}
.table-delete-btn {
  color: #606066 !important;
}
.table-delete-btn:hover {
  color: #606066 !important;
}
</style>
