import axios from '../../axios';
export default {
  state: {
    count: 0
  },
  mutations: {
    // 获取告警数
    getCount(state) {
      axios.get(this._vm.$url + 'v1/dspweb/alert/count').then((res) => {
        state.count = res.result;
      });
    }
  }
};
