<!-- 表格组件 -->
<template>
  <div class="filers">
    <div class="left">
      <el-input class="search" clearable v-model="inputValue" :placeholder="placeholder" @clear="search">
        <el-button style="width: 100%; height: 36px" slot="append" icon="el-icon-search" @click="search">搜索</el-button>
      </el-input>
      <el-button class="hide-show_btn" v-if="isFunnel" @click="funnel">
        <i class="iconfont icon-shx" :style="{ color: color }"></i>
        筛选
      </el-button>
      <el-button class="hide-show_btn" @click="resetData">
        <i class="iconfont icon-cz"></i>
        重置
      </el-button>
      <slot></slot>
    </div>
    <div class="right">
      <slot name="btn"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Table',
  props: {
    hasFilter: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    value: {
      default: ''
    },
    isFunnel: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#606266'
    }
  },
  created() {},
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    //搜索
    search() {
      this.$emit('search');
    },
    // 筛选
    filterData() {
      this.$emit('filter');
    },
    //重置
    resetData() {
      this.$emit('reset');
    },
    // 打开漏斗
    funnel() {
      this.$emit('funnel');
    }
  }
};
</script>
<style scoped lang="less">
.filers {
  display: flex;
  justify-content: space-between;
  height: 36px;
  padding: 0;
  .left {
    display: flex;
    .hide-show_btn {
      height: 36px;
      padding: 0 10px;
      margin-left: 15px;
      display: flex;
      align-items: center;
      color: #606266;
      border: 1px solid #dcdfe6;
      & > span {
        img {
          vertical-align: top;
        }
      }
    }

    .search {
      width: 280px;
      .el-input__inner {
        padding-left: 20px;
        padding-right: 30px;
      }
      /deep/.el-input-group__append {
        width: 64px;
        background-color: #3882ef;
        color: #ffffff;
        border: none;
        padding: 0px;
        text-align: center;
        height: 100%;
        line-height: 100%;
        cursor: pointer;
        .el-button {
          padding: 0px;
          margin: 0px;
          border: none;
        }
      }
    }
    .filter-item {
      width: auto;
      font-size: 14px;
      margin-left: 40px;
      color: #656a70;
      .el-select {
        width: 190px;
      }
    }
  }
}
</style>
