import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user.js";
import alert from './modules/alert.js'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    user,
    alert
  },
  plugins: [
    createPersistedState({
      paths: ['user.permissionList','user.token','user.enterprise']
    })
  ]
});
