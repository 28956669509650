function dictionary(id, data) {
  let name = '';
  data.forEach(item => {
    if (item.id === id) name = item.name;
  });
  return name;
}

export default {
  install: function(Vue) {
    Vue.prototype.getName = (id, name) => dictionary(id, name);
  }
};
