// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import { router } from '@/router';
import store from '@/store';
import * as echarts from 'echarts';
import './styles/iconfont.css';
import '@/styles/user.less';
import 'default-passive-events';
import '@/theme/index.css';
import ajax from '@/axios';
import dictionary from '@/utils/ReturnData';
import ElementUI from 'element-ui';
import { getPermission } from '@/utils/getPermission';
import filters from '@/components/filters.vue';
import { getNum, getAlert } from '@/utils/getNum';
// import 'babel-polyfill';
import moment from 'moment';

Vue.prototype.$moment = moment;
Vue.component('filters', filters);

Vue.prototype.$get = ajax.get;
Vue.prototype.$post = ajax.post;
Vue.prototype.$echarts = echarts;

Vue.prototype.$url = process.env.VUE_APP_BASE_URL;
Vue.prototype.$MQTT = process.env.VUE_APP_MQTT_URL;
Vue.prototype.$permission = getPermission;
Vue.prototype.getAlert = getAlert;
Vue.prototype.getNum = getNum;

Vue.use(dictionary);

Vue.use(ElementUI, { size: 'medium' });
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
});
